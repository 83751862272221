import { createRouter, createWebHistory } from 'vue-router'
import AuthHelper from '@/helpers/authHelper.js'

const SignUp = () => import('@/views/authentication/SignUp.vue')
const SignIn = () => import('@/views/authentication/SignIn.vue')
const OrganisationsShow = () => import('@/views/organisations/OrganisationsShow.vue')
const OrganisationsIndex = () => import('@/views/organisations/OrganisationsIndex.vue')
const OrganisationsNew = () => import('@/views/organisations/OrganisationsNew.vue')
const JamsIndex = () => import('@/views/jams/JamsIndex.vue')
const JamsNew = () => import('@/views/jams/JamsNew.vue')
const JamsShowAdmin = () => import('@/views/jams/JamsShowAdmin.vue')
const JamsEdit = () => import('@/views/jams/JamsEdit.vue')
const JamsShow = () => import('@/views/jams/JamsShow.vue')
const WorkersIndex = () => import('@/views/workers/WorkersIndex.vue')
const WorkersNew = () => import('@/views/workers/WorkersNew.vue')
const WorkersShow = () => import('@/views/workers/WorkersShow.vue')
const WorkersEdit = () => import('@/views/workers/WorkersEdit.vue')
const WorkersJamSubmissionsIndex = () => import('@/views/workers/WorkersJamSubmissionsIndex.vue')
const ButtonStyles = () => import('@/views/styles/ButtonStyles.vue')

const routes = [
  { path: '/', redirect: '/dashboard' },
  { path: '/signin', name: 'signin', component: SignIn },
  { path: '/signup', name: 'signup', component: SignUp },
  { path: '/dashboard', name: 'dashboard', component: OrganisationsShow, meta: { requiresAuth: true } },
  { path: '/organisations', name: 'organisations-index', component: OrganisationsIndex, meta: { requiresAuth: true } },
  { path: '/organisations/new', name: 'organisations-new', component: OrganisationsNew, meta: { requiresAuth: true } },
  { path: '/jams', name: 'jams-index', component: JamsIndex, meta: { requiresAuth: true } },
  { path: '/jams/new', name: 'jams-new', component: JamsNew, meta: { requiresAuth: true } },
  { path: '/jams/:id/summary', name: 'jams-show-admin', component: JamsShowAdmin, meta: { requiresAuth: true } },
  { path: '/jams/:id/edit', name: 'jams-edit', component: JamsEdit, meta: { requiresAuth: true } },
  { path: '/jams/:id', name: 'jams-show', component: JamsShow },
  { path: '/workers', name: 'workers-index', component: WorkersIndex, meta: { requiresAuth: true } },
  { path: '/workers/new', name: 'workers-new', component: WorkersNew, meta: { requiresAuth: true } },
  { path: '/workers/:id', name: 'workers-show', component: WorkersShow, meta: { requiresAuth: true } },
  { path: '/workers/:id/edit', name: 'workers-edit', component: WorkersEdit, meta: { requiresAuth: true } },
  { path: '/worker-jam-submissions/:id', name: 'worker-jam-submissions-index', component: WorkersJamSubmissionsIndex, meta: { requiresAuth: true } },
  { path: '/styles', name: 'styles', component: ButtonStyles },
  { path: '/:pathMatch(.*)*', redirect: '/dashboard' }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

router.beforeEach((to, _from, next) => {
  if (to.meta.requiresAuth && AuthHelper.getSessionFromLocalStorage() === null) {
    // TODO - named route, this doesn't work on nested routes.
    return next('signin')
  }
  return next()
})

export default router
