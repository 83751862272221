import store from '../store'
import router from '../router'

const baseApiUrl = () => {
  return `${process.env.VUE_APP_API_URL}/private/v1/`
}

const signOut = () => {
  store.dispatch('jv/delete', ['sessions/signout'])
    .then(() => {
      store.dispatch('resetState')
      removeSessionFromLocalStorage()
      removeCurrentOrganisationIdFromLocalStorage()
      router.push({ name: 'signin' })
    })
}

const setSession = (id) => {
  const session = store.getters['jv/get'](`session/${id}`)
  const sessionPayload = {
    id: session._jv.id,
    identifier: session.identity.identifier,
    email: session.identity.email,
    mobilePhone: session.identity.mobilePhone,
    accessToken: session.accessToken,
    version: process.env.VUE_APP_SESSION_VERSION,
    identityId: session.identity._jv.id
  }
  localStorage.setItem('session', JSON.stringify(sessionPayload))
}

const getSessionFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem('session'))
}

const identitySignedIn = () => {
  return getSessionFromLocalStorage != null
}

const postSignInRedirect = () => {
  const redirect = localStorage.getItem('afterSignInPathRedirect')

  if (redirect != null) {
    localStorage.removeItem('afterSignInPathRedirect')
    router.push(redirect)
  } else {
    router.push({ name: 'organisations-index' })
  }
}

const removeSessionFromLocalStorage = () => {
  localStorage.removeItem('session')
}

const setCurrentOrganisationId = (id) => {
  localStorage.setItem('currentOrganisationId', id)
}

const getCurrentOrganisationId = () => {
  return localStorage.getItem('currentOrganisationId')
}

const removeCurrentOrganisationIdFromLocalStorage = () => {
  localStorage.removeItem('currentOrganisationId')
}

export default { baseApiUrl, signOut, setSession, getSessionFromLocalStorage, identitySignedIn, postSignInRedirect, removeSessionFromLocalStorage, setCurrentOrganisationId, getCurrentOrganisationId, removeCurrentOrganisationIdFromLocalStorage }
