import { createApp } from 'vue'
import store from './store'
import router from './router'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import './assets/css/main.css'
import App from './App.vue'

const app = createApp(App)

const sentryConfig = {
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [process.env.VUE_APP_API_DOMAIN]
    })
  ],
  tracesSampleRate: 1.0
}

Sentry.init(sentryConfig)

app.use(store)
app.use(router)
app.mount('#app')
