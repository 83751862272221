import { createStore } from 'vuex'
import axios from 'axios'
import { jsonapiModule } from 'jsonapi-vuex'
import AuthHelper from '@/helpers/authHelper.js'

const api = axios.create({
  baseURL: `${AuthHelper.baseApiUrl()}`,
  headers: { 'Content-Type': process.env.VUE_APP_API_CONTENT_TYPE }
})

const authInterceptor = config => {
  config.headers.Authorization = `Bearer ${AuthHelper.getSessionFromLocalStorage()?.accessToken}`
  config.headers['Current-Organisation-Id'] = AuthHelper.getCurrentOrganisationId()
  return config
}

api.interceptors.request.use(authInterceptor)

api.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      // localStorage.setItem('afterSignInPathRedirect', this.$router.currentRoute.value.path)
      // localStorage.removeItem('session')
      // this.$router.push({ name: 'signin' })
    }
    return Promise.reject(error)
  }
)

export default createStore({
  modules: {
    jv: jsonapiModule(api)
  },
  actions: {
    resetState (context) {
      // TODO: Just loop through existing records and delete them without needing to specify them.
      context.commit('jv/clearRecords', { _jv: { type: 'identity' } })
      context.commit('jv/clearRecords', { _jv: { type: 'session' } })
      context.commit('jv/clearRecords', { _jv: { type: 'organisation' } })
      context.commit('jv/clearRecords', { _jv: { type: 'passwordlessRequest' } })
      context.commit('jv/clearRecords', { _jv: { type: 'user' } })
      context.commit('jv/clearRecords', { _jv: { type: 'jamUser' } })
      context.commit('jv/clearRecords', { _jv: { type: 'jam' } })
      context.commit('jv/clearRecords', { _jv: { type: 'jamVersion' } })
      context.commit('jv/clearRecords', { _jv: { type: 'jamBlock' } })
      context.commit('jv/clearRecords', { _jv: { type: 'jamBlockDatum' } })
      context.commit('jv/clearRecords', { _jv: { type: 'event' } })
    },
    clearRecordsByModel (context, model) {
      context.commit('jv/clearRecords', { _jv: { type: model } })
    }
  }
})
